import axios from 'axios'

export default {
    login: (data) => axios.post('login', data),
    validate: (data) => axios.post('login/validate', data),
    validateAdmin: (data) => axios.post('login/validate-admin', data),

    validateEmail: (email) => axios.post('validate-email', {email: email}),
    getUser: (id) => axios.post('get-user', {id: id}),
    activateAccount: (data) => axios.post('account/activate', data),
    recoveryAccount: (data) => axios.post('account/send-recover-password-link', data),
    recoveryPassword: (data) => axios.post('account/recover-password', data),
    storeAccount: (data) => axios.post('account/store', data),
    
    
    loginByCode : (data) => axios.post('login/by-code', data),
    forceLogin : (data) => axios.post('login/force', data),

    
}